// we will import boostrap from react-bootstrap at the bottom
// import of customized font family
@import './napp-custom-css.css';
@import 'napp-police';
@import 'napp-variables';
@import 'napp-size-variables';

$height-custom: 50px;
$height-item: 44px;
$width-item: 100%;
$backgound-color-header-icon: #d8d9dd;

:root {
    --offcanvas-width: 270px;
    --topNavbarHeight: 56px;
    --form-check-input: #0d6efd;
    --other-menu-text: #24292f;
    --card-color: #000;
    --card-border-color: rgba(0, 0, 0, 0.125);
    --card-header-bg-color: #f7f7f7;
    --card-body-bg-color: #fff;
    --offcanvas-bg-color: #{$white};
    --napp-offcanvas-header-border-bottom: #{rgba(
            $color: $gray-900,
            $alpha: 0.3
        )};
    --color-border-muted: rgb(65, 71, 87, 0.5);
    --napp-offcanvas-border-side-color: rgba(26, 25, 25, 0.2);
    --napp-sidebar-label: #414757;
    --height-off: 1px;
    --changement: #{$offcanvas-bg-color};
    --header-background-color: #{$primary};
    --napp-display-block-padding-2-19-hover-bg-color: #{rgba(
            $color: $gray-900,
            $alpha: 0.1
        )};
    --napp-display-block-padding-2-19-text-color: #{$gray-900};
    --napp-z-index-hover-bg-color: #fff;
    --napp-dark-shadow-header: #{rgba($color: $gray-900, $alpha: 0.3)};
    --napp-height-auto-header: #{rgba($color: $gray-900, $alpha: 0.3)};
    --napp-color-header-icon: $backgound-color-header-icon;
    --napp-avatar-background: #{$gray-300};
    --napp-sidebar-nav-box-shadow: rgba($color: $gray-100, $alpha: 0.2);
    --napp-submenu-hover-bg-color: #{$gray-300};
    --napp-submenu-hover-text-color: #414757;
    --napp-submenu-focus-bg-color: #{rgba($color: $primary, $alpha: 0.2)};
    --napp-submenu-focus-text-color: #{$primary};
    --napp-menu-text-color: #414757;
    --napp-menu-bg-color: #{$gray-100};
    --napp-menu-hover-bg-color: #{$gray-300};
    --napp-menu-focus-bg-color: #{$gray-300};
    --napp-menu-hover-text-color: #{$primary};
    --napp-menu-focus-text-color: #{$primary};
    --napp-menu-hover-border-color: #{$gray-900};
    --backgound-color-header-icon: #{$backgound-color-header-icon};
    --icon-color: #{$gray-900};
    --napp-footer-text-color: #{$gray-700};
    --napp-content-main-color: #{$white};
    --napp-side-bar-scroll: #{$gray-500};
    --napp-side-bar-item-color: #{$gray-700};
    --napp-border-side-color: #{$gray-300};
    --brand-text-color: #000;
    --font-family: $global-font;
    --background: #{$light-background};
    --text-color: #{$black};
    --link-color: #548;
    --napp-navbar-bgc: #{$napp-light-bg};
    --napp-nav-bg: #{$gray-900};
    --napp-submenu-sidebar-link-color: #{$gray-200};
    --napp-submenu-sidebar-link-hover-bg: #{$gray-900};
    --napp-submenu-sidebar-link-hover-color: #{$gray-200};
    --napp-link-dropdown-bg: #{$gray-900};
    --napp-link-dropdown-color: #{$gray-100};
    --napp-link-dropdown-hover-bg: #{$gray-100};
    --napp-link-dropdown-hover-color: #{$gray-900};
    --napp-sidebar-nav-bg: #{$gray-900};
    --height-custom: $height-custom;
    --subhead-card-border-color: rgba(2, 2, 2, 0.2);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#root {
    height: 100vh !important;
    width: 100vw !important;
    background: var(--background) !important;
}

body {
    overflow: hidden !important;
    background: var(--background) !important;
    font-family: var(--font-family);
    height: 100vh !important;
    width: 100vw !important;
}

[mode-theme='dark'] {
    --form-check-input: #161b22;
    --napp-display-block-padding-2-19-hover-bg-color: #{rgba(
            $color: $gray-700,
            $alpha: 0.5
        )};
    --subhead-card-border-color: rgba(255, 255, 255, 0.4);
    --color-border-muted: #21262d;
    --napp-dark-shadow-header: #000;
    --other-menu-text: #e7e9ec;
    --card-color: #{$gray-100};
    --card-border-color: #{$gray-800};
    --card-header-bg-color: #161b22;
    --card-body-bg-color: #{$gray-800};
    --napp-offcanvas-border-side-color: rgba(196, 193, 193, 0.2);
    --offcanvas-bg-color: #161b22;
    --napp-z-index-hover-bg-color: #{rgba($color: $gray-100, $alpha: 0.6)};
    --napp-offcanvas-header-border-bottom: #{rgba(
            $color: $gray-100,
            $alpha: 0.3
        )};
    --napp-display-block-padding-2-19-text-color: #e7e9ec;
    --napp-sidebar-label: #ebebee;
    --height-off: 1px;
    --header-background-color: #161b22;
    --changement: #{$gray-800};
    --napp-menu-text-color: #{$white};
    --napp-submenu-hover-bg-color: #{$gray-700};
    --napp-submenu-hover-text-color: #{$white};
    --napp-submenu-focus-bg-color: #{rgba($color: $primary, $alpha: 0.2)};
    --napp-submenu-focus-text-color: #{$gray-100};
    --icon-color: #{$gray-100};
    --napp-footer-text-color: #{$gray-100};
    --napp-content-main-color: #0d1117;
    --napp-side-bar-scroll: #{$gray-600};
    --napp-side-bar-item-color: #{$gray-100};
    --napp-border-side-color: #{$gray-100};
    --brand-text-color: #fff;
    --background: #0d1117;
    --text-color: #bcbec0;
    --link-color: #f00;
    --napp-navbar-bgc: #{$gray-900};
    --napp-nav-bg: #f00;
    --napp-submenu-sidebar-link-color: #f00;
    --napp-submenu-sidebar-link-hover-bg: #f00;
    --napp-submenu-sidebar-link-hover-color: #f00;
    --napp-link-dropdown-bg: #f00;
    --napp-link-dropdown-color: #f00;
    --napp-link-dropdown-hover-bg: #f00;
    --napp-link-dropdown-hover-color: #f00;
    --napp-sidebar-nav-bg: #f00;
}

.napp-header {
    position: fixed !important;
    width: 100%;
    height: 64px !important;
    border-bottom: 1px solid var(--napp-border-side-color) !important;
    padding: 0px 16px 0px 0px !important;
}

.napp-header-show {
    transition-timing-function: ease-in !important;
    transition: all 0.5s !important;
}

.header-left {
    display: flex !important;
    flex-direction: row !important;
}

.header-right {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    width: auto !important;
}

.header-left-span {
    padding: 5px !important;
    border-right: 1px solid var(--napp-border-side-color) !important;
    margin-right: 5px !important;
    font-size: #{$header-font-size};
}

.header-left-span-brand {
    color: var(--brand-text-color);
}

.napp-navbar {
    width: 200px;
    height: 100%;
    background-color: var(--napp-navbar-bgc);
}

.napp-nav {
    background: var(--napp-nav-bg);
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.napp-nav-wrapper {
    max-width: 250px;
    min-width: 125px;
    margin: 0 !important;
}

.main-sidebar {
    background: #fff !important;
    z-index: 2;
}

.z-1020 {
    z-index: 1200 !important;
}

.btn-toolbar {
    z-index: 0 !important;
}

@media screen and (max-width: 900px) {
    .napp-nav-wrapper.wrapper-flex {
        max-width: 900px;
        display: flex;
        column-gap: 2;
        flex-direction: row;
        overflow-x: scroll;
    }

    .wrapper-flex {
        margin-bottom: 0.5rem !important;
        overflow-x: scroll;
    }
}

.custom-canvas-body {
    all: revert !important;
    margin-left: 0px !important;
}

.napp-link {
    margin-left: 2rem;
    font-size: 2rem;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.napp-submenu-sidebar-link {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    color: var(--napp-side-bar-item-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 0px;
    list-style: none;
    width: #{$width-item};
    height: #{$height-item};
    border-radius: 5px;
    text-decoration: none;

    &:hover {
        background: var(--napp-submenu-hover-bg-color);
        /* border-left: 4px solid $gray-200; */
        cursor: pointer;
        color: rgba($color: $primary, $alpha: 0.8);
    }
}

.napp-submenu-sidebar-link.napp-submenu-sidebar-link-active {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 0px;
    list-style: none;
    width: #{$width-item};
    height: #{$height-item};
    border-radius: 5px;
    text-decoration: none;
    background: var(--napp-submenu-focus-bg-color);
    color: var(--napp-submenu-focus-text-color) !important;

    &:hover {
        background: var(--napp-submenu-hover-bg-color);
        /* border-left: 4px solid $gray-200; */
        cursor: pointer;
        color: var(--napp-submenu-focus-text-color) !important;
    }
}

.napp-link-dropdown {
    background: var(--napp-menu-bg-color);
    width: #{$width-item};
    height: #{$height-item};
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--napp-menu-text-color);

    &:hover {
        background: var(--napp-menu-hover-bg-color);
        cursor: pointer;
        color: var(--napp-menu-focus-text-color);
        border-left: 4px solid var(--napp-menu-hover-border-color);
    }
}

.napp-link-dropdown.napp-link-dropdown-active {
    width: #{$width-item};
    height: #{$height-item};
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    background: var(--napp-menu-focus-bg-color);
    color: var(--napp-menu-focus-text-color);
}

.napp-sidebar-label {
    text-decoration: none !important;
    margin-left: 16px;
}

.napp-sidebar-label-dark {
    color: #fff !important;
    text-decoration: none !important;
    margin-left: 16px;

    &:hover {
        color: #000 !important;
    }
}

.napp-layout {
    z-index: -1 !important;
    display: flex;
    flex-direction: column;
}

.container-fluid.napp-layout-flex {
    /* padding-top: 1px;
    width: 100vw !important;
    display: flex;
    height: 100vh !important;
    flex-direction: row; */
    color: var(--text-color);
    width: 100vw;
    height: 92vh;
    padding-top: 10px;
    background: var(--napp-content-main-color);
    overflow-y: scroll !important;

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: var(--napp-side-bar-scroll);
    }

    &::-webkit-scrollbar {
        width: 6px;
    }
}

.napp-layout-content-flex {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.napp-layout-content-flex {
    overflow-x: scroll;

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: var(--napp-side-bar-scroll);
    }

    &::-webkit-scrollbar {
        width: 6px;
    }
}

.offcanvas-header {
    color: var(--text-color) !important;
    border-bottom: 1px solid var(--napp-offcanvas-header-border-bottom) !important;
}

/* .napp-main-content {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: 100% !important;
    background-color: var(--napp-content-main-color);
} */

.napp-sidebar-nav {
    box-shadow: 3px 0 var(--napp-sidebar-nav-box-shadow);
    height: auto;
    transition: 350ms;
}

.napp-footer {
    /* position: fixed !important; */
    text-align: center;
    width: 100%;
    margin-top: 20px !important;
    padding-top: 10px !important;
    color: var(--napp-footer-text-color) !important;
    background-color: var(--napp-content-main-color) !important;
    /* margin-top: 16px !important;
    width: 1000px !important; */
}

.btn--rounded {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    padding-left: -50% !important;
}

.btn--rounded-bord {
    width: auto !important;
    height: $height-custom !important;
    border-radius: calc(#{$height-custom} / 2) !important;

    &:hover {
        border-radius: calc(#{$height-custom} / 3) !important;
    }
}

/* .btn-secondary{
    color: #fff !important;
    background-color: #000 !important;
    border-color: #6c757d !important;
    width: 500px !important;
} */
/*# Code for customing css of NAPP component */

// suppréssion de l'effet border lorsque l'élément est focus
.btn:focus {
    box-shadow: 0 0 0 0 !important;
}

/***
// style for regular button **
***/

// without border radius
.btn-regular {
    width: auto;
    height: #{$regular-button-height-size} !important;
    border-radius: 0 !important;
}

// with border radius
.btn-regular.btn-regular-rounded {
    border-radius: 5px !important;
}

.btn-regular.btn-rounded-corner {
    width: 250px !important;
    border-radius: calc(#{$regular-button-height-size} / 2) !important;
}

// with icon only

.btn-icon-button {
    width: auto;
    height: currentWidth !important;
    border-radius: 50% !important;
}

.napp-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--napp-avatar-background);

    &:hover + .display-none {
        display: block;
        visibility: visible;
    }
}

/* .napp-avatar.avatar-focus{
    &:focus{
        background-color: ;
    }
} */

.icon-custom {
    color: var(--icon-color);
    width: 40px;
    height: 40px;
    padding: 11px;
}

@media screen and (max-width: 480px) {
    .custom-nav-link.icon-to-hide {
        display: none;
    }
}

.icon-custom.header-icon {
    color: #fff !important;

    &:hover {
        background: rgba(
            $color: var(--napp-color-header-icon),
            $alpha: 0.5
        ) !important;
        border-radius: 20px;
    }
}

.header-right-items {
    padding: 8px !important;
}

/* For the new look */
/*****
* Changing CSS
****/
.custom-nav {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
}

.custom-nav-link {
    padding-right: 30px !important;
}

.custom-nav-brand {
    padding-left: 100px !important;
}

.header-brand-span {
    margin-left: 20px !important;
    padding-left: 10px !important;
    border-left: 1px solid var(--napp-border-side-color) !important;
}

.dark-shadow-header {
    box-shadow: 0px 4px 4px var(--napp-dark-shadow-header);
}

.elevation-header {
    width: 100vw !important;
    z-index: calc(#{$zindex-offcanvas} + 800) !important;
}

.elevation-header.dark-shadow-header {
    background: var(--header-background-color) !important;
}

.custom-nav-offcanvas {
    background-color: var(--offcanvas-bg-color) !important;
    z-index: calc(#{$zindex-offcanvas} + 0) !important;
    box-shadow: 4px 0px 4px var(--napp-dark-shadow-header);
    border-right: unset !important;
    width: 278px !important;
    overflow-x: scroll !important;
    scrollbar-width: thin !important;

    &::-webkit-scrollbar-thumb {
        border-radius: 5px !important;
        background: var(--napp-side-bar-scroll) !important;
    }

    &::-webkit-scrollbar {
        width: 6px !important;
    }
}

.custom-nav-offcanvas-dark {
    background-color: var(--offcanvas-bg-color) !important;
    z-index: calc(#{$zindex-offcanvas} + 0) !important;
    border-right: var(--height-off) solid rgba($color: $gray-100, $alpha: 0.2) !important;
    width: 278px !important;
    overflow-x: scroll !important;
    scrollbar-width: thin !important;

    &::-webkit-scrollbar-thumb {
        border-radius: 5px !important;
        background: var(--napp-side-bar-scroll) !important;
    }

    &::-webkit-scrollbar {
        width: 6px !important;
    }
}

.offcanvas-body {
    color: #548 !important;
    padding: 1rem 0px 1rem 0px !important;
    overflow-x: hidden !important;
}

.height-auto {
    padding-top: 5px;
    padding-bottom: 10px;
    top: 30px;
    right: 30px;
    height: auto;
    width: auto;
    border-radius: 5px;
    border: 1px solid var(--card-border-color);
    box-shadow: 0px 4px 4px var(--napp-height-auto-header);
    color: var(--text-color);
    background-color: var(--card-header-bg-color) !important;
}

.mb-1 {
    font-size: 0.875rem;
    margin-bottom: 0.25rem !important;
}

.diplay-flex-row {
    display: flex;
    flex-direction: row;
}

.z-index-hover {
    z-index: inherit;
    background: var(--napp-z-index-hover-bg-color);
}

.display-block.padding-2-19 {
    text-align: left;
    display: block;
    padding: 2px 19px;
    text-decoration: none;
    color: var(--napp-display-block-padding-2-19-text-color);
    font-weight: lighter;
    font-size: 14px !important;
    margin-bottom: 5px;

    &:hover {
        background: var(--napp-display-block-padding-2-19-hover-bg-color);
        border-radius: 5px;
    }
}

.separator {
    height: 1px !important;
}

.wh-.avatar {
    padding-top: 3px;
}

.margin-left {
    margin: 0px 0px 0px 16px;
}

.margin-top-16 {
    margin: 16px 0px 0px;
}

.margin-left.diplay-flex-column.ms-3.lh-1 {
    text-align: left !important;
}

.diplay-flex-row.margin-top-16 {
    align-items: center;
    padding: 2px 19px;
}

.display-none {
    top: 50px;
    right: 15px;
    position: absolute;
    padding-top: 15px;
    padding-bottom: 10px;
    display: none;
    background: transparent;

    &:hover {
        display: block;
    }
}

.ActionList-item {
    background: transparent;
    list-style: none;
    text-decoration: none;
    border-radius: 6px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 5px 0px 5px;
    margin-bottom: 0.3rem;

    &:hover {
        background: var(--napp-submenu-hover-bg-color);
        cursor: pointer;
        border-left: 5px solid rgba($color: $primary, $alpha: 0.8);
        padding: 0px 0px 0px 0px;
    }
}

.ActionList-item-submenu {
    background: transparent;
    list-style: none;
    text-decoration: none;
    border-radius: 6px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.3rem;
    padding-left: 1.5rem;

    &:hover {
        background: var(--napp-submenu-hover-bg-color);
        cursor: pointer;
        border-left: 5px solid rgba($color: $primary, $alpha: 0.8);
        padding: 0px 0px 0px 1rem;
    }
}
.ActionList-item-submenu.ActionList-item-submenu-active {
    list-style: none;
    text-decoration: none;
    border-radius: 6px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.3rem;
    background: var(--napp-submenu-hover-bg-color);
    cursor: pointer;
    border-left: 5px solid rgba($color: $primary, $alpha: 0.8);
    padding: 0px 0px 0px 1rem;
}

.ActionList-item.ActionList-item-active {
    list-style: none;
    text-decoration: none;
    border-radius: 6px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: var(--napp-submenu-hover-bg-color);
    cursor: pointer;
    border-left: 5px solid rgba($color: $primary, $alpha: 0.8);
    padding: 0px 10px 0px 0px;
}

@media screen and (max-width: 576px) {
    .ActionList-item {
        background: transparent;
        list-style: none;
        text-decoration: none;
        border-radius: 6px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px 0px 5px;

        &:hover {
            background: var(--napp-submenu-hover-bg-color);
            cursor: pointer;
            border-left: unset;
            border-top: 5px solid rgba($color: $primary, $alpha: 0.8);
            padding: 0px 0px 0px 0px;
        }
    }

    .ActionList-item.ActionList-item-active {
        list-style: none;
        text-decoration: none;
        border-radius: 6px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--napp-submenu-hover-bg-color);
        cursor: pointer;
        border-left: unset;
        border-top: 5px solid rgba($color: $primary, $alpha: 0.8);
        padding: 0px 0px 0px 0px;
    }
}

.ActionList-item-label {
    text-decoration: none;
    list-style: none;
    font-size: 14px;
    position: relative;
    font-weight: 400;
    line-height: 20px;
    color: var(--other-menu-text);
}

.card {
    color: var(--card-color) !important;
    border: 1px solid var(--card-border-color) !important;
}

.card-header {
    background-color: var(--card-header-bg-color) !important;
}

.card-body {
    background-color: var(--card-body-bg-color) !important;
}

.form-control {
    background-color: transparent !important;
    color: var(--text-color) !important;

    &:focus {
        background-color: transparent !important;
        color: var(--text-color) !important;
    }
}

.form-check-input:checked {
    background-color: var(--form-check-input) !important;
    border-color: var(--form-check-input) !important;
}

.napp-sign-up {
    overflow-y: scroll;
    height: 95vh;
    width: 100vw;

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: var(--napp-side-bar-scroll);
    }

    &::-webkit-scrollbar {
        width: 6px;
    }
}

.custom-logout-btn {
    position: inherit;
}

.user-email {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
}

// config section
.Subhead {
    display: flex;
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--color-border-muted); //21262d
    flex-flow: row wrap;
    justify-content: flex-end;
}

.Subhead-heading {
    font-size: 24px;
    font-weight: 400;
    flex: 1 1 auto;
    order: 0;
}

.Subhead-heading-label {
    font-size: 16px;
    font-weight: 600;
}

.Subhead-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: border-box;
    padding-left: 10px;
    padding-top: 5px;
    border: 1px solid var(--subhead-card-border-color);
    border-radius: 0.25rem;
}

.company-logo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-image: url('../images/photo-avatar-profil.png');
    background-size: cover;
    border: rgba(0, 0, 0, 0.2) solid 1px;
    // margin-bottom: 10px;
}

// btn bootstrap changes
.btn {
    font-size: 14px !important;
}

// separator
.separator-right {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator-right::before,
.separator-right::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.separator-right:not(:empty)::before {
    margin-right: 0.25em;
}

.separator-right:not(:empty)::after {
    margin-left: 0.25em;
}

// fieldset
fieldset {
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

.note {
    min-height: 17px;
    margin: 4px 0 2px;
    font-size: 12px;
    color: var(--color-fg-muted); // #8b949e
}

dd {
    margin: 0px !important;
    padding: 0px !important;
}

dt {
    margin: 0px !important;
    padding: 0px !important;
}

.bg-gray-50 {
    background-color: #f9fafb;
}

.row.dl {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.pointer {
    cursor: pointer;
}

@import '~bootstrap/scss/bootstrap.scss';
